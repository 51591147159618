import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import dumper_loaded from "../img/dumper_loaded.png";
import dumper_unloaded from "../img/dumper_unloaded.png";

const DumperLoadedUnloaded = (props) => {
  const { dumper = { label: "" } } = props;

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent style={{ paddingBottom: 8 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ textAlign: "left" }}>
            <Typography
              variant="subtitle2"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Dumper
            </Typography>
          </Grid>
          {dumper && dumper.label && (
            <>
              <Grid item xs={6} style={{ paddingTop: 0, textAlign: "left" }}>
                <Typography variant="h6">
                  {dumper.label === "dumper_loaded" ? "Loaded" : "Unloaded"}
                </Typography>
              </Grid>

              <Grid item xs={6} style={{ paddingTop: 0, textAlign: "right" }}>
                <img
                  width={80}
                  src={
                    dumper.label === "dumper_loaded"
                      ? dumper_loaded
                      : dumper_unloaded
                  }
                  alt="dumper_image"
                />
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DumperLoadedUnloaded;
