import Router from "./Router";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import { UserProvider } from "@datwyler/shared-components";

const App = () => {
  const navigate = useNavigate();

  return (
    <UserProvider navigate={navigate}>
      <Router />
    </UserProvider>
  );
};

export default App;
