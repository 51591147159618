import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const WorkOrIdle = (props) => {
  const { visoAiAnalytics, getLabel } = props;

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent style={{ paddingBottom: 8 }}>
        <Typography variant="h6" gutterBottom style={{ fontWeight: "bold" }}>
          Status
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={3} sx={{ textAlign: "left" }}>
            <Typography
              variant="subtitle2"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Object
            </Typography>
          </Grid>
          <Grid item xs={2} sx={{ textAlign: "left" }}>
            <Typography
              variant="subtitle2"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Work or Idle
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ textAlign: "left" }}>
            <Typography
              variant="subtitle2"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Working Time
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <Typography
              variant="subtitle2"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Driven Meters
            </Typography>
          </Grid>
          {visoAiAnalytics.objectResult.map((vehicle) => {
            return (
              <>
                <Grid item xs={3} style={{ paddingTop: 0, textAlign: "left" }}>
                  <Typography variant="subtitle2">
                    {getLabel(vehicle.label)}
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{ paddingTop: 0, textAlign: "left" }}>
                  <Typography variant="subtitle2">
                    {vehicle.workStatus ? "Work" : "Idle"}
                  </Typography>
                </Grid>
                <Grid item xs={3} style={{ paddingTop: 0, textAlign: "left" }}>
                  <Typography variant="subtitle2">{}</Typography>
                </Grid>
                <Grid item xs={4} style={{ paddingTop: 0, textAlign: "right" }}>
                  <Typography variant="subtitle2">
                    {vehicle.distance.toFixed(3)}
                  </Typography>
                </Grid>
              </>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default WorkOrIdle;
