import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import exclamation_red from "../img/exclamation_red.png";
import ok_green from "../img/ok_green.png";

const ObjectCollision = (props) => {
  const { objectsWithCollision, getLabel } = props;

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent style={{ paddingBottom: 8 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ textAlign: "left" }}>
            <Typography
              variant="subtitle2"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Objects with collision
            </Typography>
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 0, textAlign: "left" }}>
            {objectsWithCollision.map((object) => {
              return (
                <Typography variant="h6">{getLabel(object.label)}</Typography>
              );
            })}
          </Grid>
          <Grid item xs={6} style={{ paddingTop: 0, textAlign: "right" }}>
            <img
              src={objectsWithCollision.length > 0 ? exclamation_red : ok_green}
              width={60}
              alt="collision status"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ObjectCollision;
