import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const DetectedObjects = (props) => {
  const { visoAiAnalytics, getLabel } = props;

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent style={{ paddingBottom: 8 }}>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ textAlign: "left" }}>
            <Typography
              variant="subtitle2"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Detected objects
            </Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            <Typography
              variant="subtitle2"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Probability
            </Typography>
          </Grid>
          {visoAiAnalytics.objectResult.map((vehicle) => {
            return (
              <>
                <Grid item xs={6} style={{ paddingTop: 0, textAlign: "left" }}>
                  <Typography variant="subtitle2">
                    {getLabel(vehicle.label)}
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ paddingTop: 0, textAlign: "right" }}>
                  <Typography
                    variant="subtitle2"
                    style={{ fontWeight: "bold" }}
                  >
                    {Math.floor(vehicle.confidence * 100)} %
                  </Typography>
                </Grid>
              </>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DetectedObjects;
