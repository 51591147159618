import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const ParkingArea = (props) => {
  const { visoAiAnalytics, getLabel } = props;

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent style={{ paddingBottom: 8 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ textAlign: "left" }}>
            <Typography
              variant="subtitle2"
              gutterBottom
              style={{ fontWeight: "bold" }}
            >
              Parking Area:
            </Typography>
          </Grid>
          {visoAiAnalytics.parkingResult.map((parkedVehicle) => {
            return (
              <>
                <Grid item xs={4} style={{ paddingTop: 0, textAlign: "left" }}>
                  <Typography variant="subtitle2">
                    {parkedVehicle.parkingLocation}
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ paddingTop: 0, textAlign: "right" }}>
                  <Typography variant="subtitle2">
                    {getLabel(parkedVehicle.parkedObject)}
                  </Typography>
                </Grid>
                <Grid item xs={4} style={{ paddingTop: 0, textAlign: "right" }}>
                  <Typography
                    variant="subtitle2"
                    style={{
                      fontWeight: "bold",
                      color: parkedVehicle.parked_status ? "red" : "green",
                    }}
                  >
                    {parkedVehicle.parkedStatus ? "Occupied" : "Free"}
                  </Typography>
                </Grid>
              </>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ParkingArea;
