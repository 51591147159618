import React, { useState } from "react";
import App from "./App";
// @ts-ignore
import { ApiProvider, SnackBarProvider, LOCALES, messages } from "@datwyler/shared-components";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";

export default function Root(props: any) {
  const locale = LOCALES.ENGLISH;
  const [currentLocale, setCurrentLocale] = useState(locale);

  return (
    <React.StrictMode>
      <IntlProvider
        key={currentLocale}
        messages={messages[currentLocale]}
        locale={currentLocale}
        defaultLocale={LOCALES.ENGLISH}
        //onError={onIntlError}
      >
        <ApiProvider BASE_URL={props.BASE_URL}>
          <SnackBarProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </SnackBarProvider>
        </ApiProvider>
      </IntlProvider>
    </React.StrictMode>
  );
}
