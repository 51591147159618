import { useState, useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import VideoStream from "../components/VideoStream";
import DetectedObjects from "../components/DetectedObjects";
import ParkingArea from "../components/ParkingArea";
import WorkOrIdle from "../components/WorkOrIdle";
import DumperLoadedUnloaded from "../components/DumperLoadedUnloaded";
import ObjectCollision from "../components/ObjectCollision";
import GatewayInfo from "../components/GatewayInfo";
// @ts-ignore
import { ApiContext } from "@datwyler/shared-components";

const VisoAi = () => {
  const { BASE_URL, apiInstance }: any = useContext(ApiContext);
  const [visoAiAnalytics, setVisoAiAnalytics] = useState({
    objectResult: [],
    parkingResult: [],
  });
  const [dumper, setDumper] = useState({ label: "" });
  const [objectsWithCollision, setObjectsWithCollision] = useState([]);
  const [rutDevice, setRutDevice] = useState({
    info: { name: "" },
    detail: { connectionState: "", firmware: "", connectionUptime: "" },
    status: { value: "" },
  });

  const getAllDevices = () => {
    apiInstance
      .get(BASE_URL + "/devices")
      .then((response) => {
        if (response.data) {
          const tempDevices = response.data;
          if (tempDevices) {
            const tempRUTDevice = getRutDevice(tempDevices);
            if (tempRUTDevice) getDeviceDetail(tempRUTDevice.deviceId);
          }
        }
      })
      .catch((error) => {
        //console.log("error", error);
      });
  };

  const getDeviceDetail = (deviceId) => {
    apiInstance
      .get(BASE_URL + "/devices/" + deviceId + "/detail")
      .then((response) => {
        if (response.data) {
          if (response.data) {
            setRutDevice(response.data);
          }
        }
      })
      .catch((error) => {
        //console.log("error", error);
      });
  };

  const getAnalytics = () => {
    apiInstance
      .get(BASE_URL + "/visoai")
      .then((response) => {
        if (response.data) {
          const data = orderByTID(response.data);
          setVisoAiAnalytics(data);
        }
      })
      .catch((error) => {
        //console.log("error", error);
      });
  };

  const orderByTID = (data) => {
    const objectResult = data.objectResult;
    objectResult.sort((a, b) => (a.tid > b.tid ? 1 : -1));
    data.objectResult = objectResult;
    return data;
  };

  const getRutDevice = (deviceList) => {
    return deviceList.find((device) => device.model === "RUTX11");
  };

  useEffect(() => {
    getAllDevices();
    getAnalytics();
    const interval = setInterval(() => {
      getAnalytics();
      getAllDevices();
    }, 1000); // every 1 sec
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (visoAiAnalytics.objectResult.length > 0) {
      setDumper(getDumper());
      setObjectsWithCollision(getCollistionObjects());
    }
  }, [visoAiAnalytics]);

  const getDumper = () => {
    return visoAiAnalytics.objectResult.find(
      (vehicle) => vehicle.label.substring(0, 6) === "dumper"
    );
  };

  const getCollistionObjects = () => {
    return visoAiAnalytics.objectResult.filter(
      (vehicle) => vehicle.collisionStatus > 0
    );
  };

  const getLabel = (key) => {
    switch (key) {
      case "excavator":
        return "Excavator";
      case "wheel_loader":
        return "Wheel Loader";
      case "dumper_loaded":
      case "dumper_empty":
        return "Dumper";
      default:
        return "";
    }
  };

  return (
    <Box style={{ marginLeft: 16, marginTop: 16 }}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <VideoStream />
        </Grid>
        <Grid item xs={6}>
          <Box style={{ paddingRight: 16 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <DetectedObjects
                  visoAiAnalytics={visoAiAnalytics}
                  getLabel={getLabel}
                />
              </Grid>
              <Grid item xs={12}>
                <ParkingArea
                  visoAiAnalytics={visoAiAnalytics}
                  getLabel={getLabel}
                />
              </Grid>
              <Grid item xs={12}>
                <DumperLoadedUnloaded dumper={dumper} />
              </Grid>
              <Grid item xs={12}>
                <ObjectCollision
                  objectsWithCollision={objectsWithCollision}
                  getLabel={getLabel}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box style={{ paddingRight: 16 }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <GatewayInfo rutDevice={rutDevice} />
              </Grid>
              <Grid item xs={6}>
                <WorkOrIdle
                  visoAiAnalytics={visoAiAnalytics}
                  getLabel={getLabel}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VisoAi;
