import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const GatewayInfo = (props) => {
  const { rutDevice } = props;

  return (
    <>
      <Typography variant="subtitle2">Managed Gateway Dashboard</Typography>
      <Typography variant="subtitle1">Teltonika Gateway</Typography>
      <Card sx={{ minWidth: 275, marginBottom: 2, marginRight: 2 }}>
        <CardContent style={{ paddingBottom: 8 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ textAlign: "left" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ textAlign: "left" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ textAlign: "left" }}>
                      <Typography variant="subtitle2">Device Name:</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "left" }}>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: "bold" }}
                      >
                        {rutDevice?.info?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ textAlign: "left" }}>
                      <Typography variant="subtitle2">
                        Connection State:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "left" }}>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: "bold" }}
                      >
                        {rutDevice?.detail?.connectionState}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ textAlign: "left" }}>
                      <Typography variant="subtitle2">Signal:</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "left" }}>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: "bold" }}
                      >
                        {rutDevice?.status?.value}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ textAlign: "left" }}>
                      <Typography variant="subtitle2">Firmware:</Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "left" }}>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: "bold" }}
                      >
                        {rutDevice?.detail?.firmware}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={6} sx={{ textAlign: "left" }}>
                      <Typography variant="subtitle2">
                        Connection Uptime:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sx={{ textAlign: "left" }}>
                      <Typography
                        variant="subtitle2"
                        style={{ fontWeight: "bold" }}
                      >
                        {rutDevice?.detail?.connectionUptime}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default GatewayInfo;
