import { Route, Routes } from "react-router-dom";
import VisoAi from "./containers/VisoAi";

const Router = (props: any) => {
  return (
    <Routes>
      <Route path="/visoai" element={<VisoAi />} />
    </Routes>
  );
};

export default Router;
