import { useEffect } from "react";
import Card from "@mui/material/Card";

function loadImageFromUrl(url, callback) {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4 && xhr.status == 200) {
      const data = JSON.parse(xhr.responseText);
      if (data.image) {
        callback("data:image/jpeg;base64," + data.image);
      } else {
        callback(null);
      }
    }
  };
  xhr.send();
}

const VideoStream = () => {
  useEffect(() => {
    refreshImage();
  }, []);

  function refreshImage() {
    const streamUrl =
      "http://192.168.0.185:1880/video/stream?id=7c30aab9.9b30b4_1";
    const imgElement = document.getElementById("streamImage");

    loadImageFromUrl(streamUrl + "&random=" + Math.random(), function (src) {
      if (imgElement && src) {
        // @ts-ignore
        imgElement.src = src;
      }
      setTimeout(refreshImage, 100); // Refresh every 1000 ms (1 second)
    });
  }

  return (
    <Card sx={{ display: "flex" }}>
      <img
        id="streamImage"
        src=""
        alt="Video Stream"
        style={{ width: "100%" }}
      ></img>
    </Card>
  );
};

export default VideoStream;
